import Plyr from 'plyr';
import 'plyr/dist/plyr.css'

let el = document.querySelector('#Plyr');
// if( ! el) return;

let options = {
  controls: [
    'play-large',
    'play',
    'progress',
    'current-time',
    'duration',
    'mute',
    'volume',
    'fullscreen',
  ],
  // iconPrefix: 'svg',
  // loadSprite: false,
};

let player = new Plyr(el, options);
