// Convert a unit to rem, eg...
// p-4 -> 1rem;
function rem(i) {
  return `${(i * 4) / 16}rem`;
}

const makeRange = (from, to, step, cb) => {
  const config = {};
  for (let i = from; i <= to; i += step) {
    config[i] = cb(i);
  }
  return config;
};

const spacing = {
  px: '1px',
  '2px': '2px',
  '3px': '3px',
  '23px': '23px',
  '1em': '1em',
  '1.5em': '1.5em',
  '1.5': '0.375rem',
  '2.5': '0.625rem',
  // It's a lot. But, this is the design studio we're in,
  // you need a lot of random sizes, so let's just lean on purge...
  ...makeRange(0, 80, 1, rem),
  ...makeRange(80, 200, 4, rem),
};

const columns = {
  '1/2': '50%',
  '2/2': '100%',
  '1/3': '33.333333%',
  '2/3': '66.666667%',
  '3/3': '100%',
  '1/4': '25%',
  '2/4': '50%',
  '3/4': '75%',
  '4/4': '100%',
  '1/5': '20%',
  '2/5': '40%',
  '3/5': '60%',
  '4/5': '80%',
  '5/5': '100%',
  '1/6': '16.666667%',
  '2/6': '33.333333%',
  '3/6': '50%',
  '4/6': '66.666667%',
  '5/6': '83.333333%',
  '6/6': '100%',
  '1/7': '14.285714%',
  '2/7': '28.571428%',
  '3/7': '42.857143%',
  '4/7': '57.142857%',
  '5/7': '85.714286%',
  '6/7': '100%',
  '7/7': '100%',
  '1/8': '12.5%',
  '2/8': '25%',
  '3/8': '37.5%',
  '4/8': '50%',
  '5/8': '62.5%',
  '6/8': '75%',
  '7/8': '87.5%',
  '1/10': '10%',
  '2/10': '20%',
  '3/10': '30%',
  '4/10': '40%',
  '5/10': '50%',
  '6/10': '60%',
  '7/10': '70%',
  '8/10': '80%',
  '9/10': '90%',
  '10/10': '100%',
  '1/12': '8.333333%',
  '2/12': '16.666667%',
  '3/12': '25%',
  '4/12': '33.333333%',
  '5/12': '41.666667%',
  '6/12': '50%',
  '7/12': '58.333333%',
  '8/12': '66.666667%',
  '9/12': '75%',
  '10/12': '83.333333%',
  '11/12': '91.666667%',
  '12/12': '100%',
};

module.exports = {
  prefix: '',
  important: false,
  separator: ':',
  theme: {
    aspectRatio: {
      '1x1': 1,
      '3x2': 3 / 2,
      '16x9': 16 / 9,
      '112x67': 112 / 67,
    },
    fill: {
      current: 'currentColor',
      transparent: 'transparent',
    },
    screens: {
      xs: { min: '480px' },
      '-sm': { max: '639px' },
      sm: { min: '640px' },
      '-md': { max: '767px' },
      md: { min: '768px' },
      lg: { min: '1024px' },
      xl: { min: '1280px' },
      '2xl': { min: '1440px' },
      '3xl': { min: '1600px' },
      '4xl': { min: '1920px' },
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      inherit: 'inherit',

      // Use CSS vars for themes:
      // theme: 'var(--theme-color, rgb(30, 34, 169) )',
      'gray-50': '#fffdf7',
      'gray-100': '#d8d8d8',
      'gray-200': '#bac3c3',
      'gray-300': '#C9C9C9',
      teal: '#19A387',
      'green-100': '#19383a',
      'green-200': '#173234',
      'green-300': '#0d2123',
      'green-400': '#132d2f',
      'green-500': '#00181a',

      'green-600': '#053139',

      // Use make range to create a range of opacity, eg
      black: {
        DEFAULT: 'rgb(0,0,0)',
        // will produce black-10, black-20, eg rgba(0,0,0,0.1) and so on....
        ...makeRange(10, 90, 10, (i) => `rgba(0,0,0,${i / 100})`),
      },
      white: {
        DEFAULT: 'rgb(255,255,255)',
        ...makeRange(10, 90, 10, (i) => `rgba(255,255,255,${i / 100})`),
      },
  },

    spacing: {
      ...spacing,
      ...columns,
    },

    namedWidths: {
      xs: '20rem', // 320px
      sm: '24rem', // 384px
      md: '27.375rem', // 438px
      lg: '32rem', // 512px
      xl: '36rem', // 576px
      '2xl': '43.25rem', // 692px
      '3xl': '48rem', // 768px
      '4xl': '54.875rem', // 878px
      '5xl': '58.25rem', // 932px
      '6xl': '69rem', // 1104px
      '7xl': '73.125rem', // 1170px
      '8xl': '33.5rem', // 536px
      full: '100%',
      screenw: '100vw',
      screenh: '100vh',
      screenhjs: 'calc((var(--vh, 1vh) * 100))',
    },
    borderColor: (theme) => ({
      ...theme('colors'),
      DEFAULT: 'currentColor',
    }),
    fontFamily: {
      // Tailwind uses `sans` on `html` as part of preflight
      sans: [
        'axiforma',
        'ui-sans-serif',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      serif: [
        'Georgia',
        'Cambria',
        '"Times New Roman"',
        'Times',
        'serif',
      ],
      mono: [
        'Monaco',
        'Consolas',
        '"Liberation Mono"',
        '"Courier New"',
        'monospace',
      ],
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.2rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      // Fonts from 10px to 60px, in REM:
      // eg text-10, text-11 ... text-60
      ...makeRange(10, 60, 1, (i) => `${i / 16}rem`),
      // text-62, text-64 etc...
      ...makeRange(62, 70, 2, (i) => `${i / 16}rem`),
    },

    // For all "sizes", use the same approach,
    // but it's a lot going in. It's flexible, and purge helps us,
    // so I think it's fine, but you may wish for clarity.
    margin: (theme, { negative }) => ({
      auto: 'auto',
      ...theme('spacing'),
      ...negative(theme('spacing')),
    }),
    width: (theme) => ({
      auto: 'auto',
      max: 'max-content',
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    height: (theme) => ({
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    padding: (theme) => ({
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    maxHeight: (theme) => ({
      full: '100%',
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    maxWidth: (theme) => ({
      max: 'max-content',
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    minHeight: (theme) => ({
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    minWidth: (theme) => ({
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    opacity: {
      ...makeRange(0, 100, 5, (o) => `${o / 100}`),
    },
    extend: {
      letterSpacing: {
        alpha: '1px',
        beta: '1.2px',
        delta: '0.8px',
        epsilon: '-0.94px',
        zeta: '-0.77px',
      },
      lineHeight: {
        alpha: '1.3',
        beta: '1.166666667',
        delta: '1.3125',
        epsilon: '1.2',
      },
      boxShadow: {
        DEFAULT: '0 0 15px 0 rgba(0, 0, 0, 0.5)',
      },
      borderRadius: {
        alpha: '10px',
      },
      scale: {
        '101': '1.01',
      },
    },

    debugScreens: {
      position: ['bottom', 'right'],
    },
  },
  variants: {
    aspectRatio: ['responsive'],
    backgroundColor: [
      'responsive',
      'hover',
      'focus',
      'focus-within',
      'group-hover',
    ],
    backgroundOpacity: [
      'responsive',
      'hover',
      'focus',
      'active',
      'group-hover',
    ],
    borderColor: ['responsive', 'hover', 'focus', 'group-hover'],
    borderOpacity: ['responsive', 'hover', 'focus', 'group-hover'],
    boxShadow: ['responsive', 'hover', 'focus', 'focus-within', 'group-hover'],
    fill: ['responsive', 'group-hover'],
    fontWeight: ['responsive', 'hover', 'focus'],
    opacity: ['responsive', 'hover', 'focus', 'group-hover', 'disabled'],
    outline: ['responsive', 'focus'],
    pointerEvents: ['hover', 'focus', 'disabled'],
    scale: ['responsive', 'hover', 'group-hover'],
    textColor: ['responsive', 'group-hover', 'hover', 'focus'],
    textDecoration: ['responsive', 'hover', 'group-hover', 'focus'],
    translate: ['responsive', 'hover', 'group-hover'],
  },
  corePlugins: {
    container: false,
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('tailwindcss-debug-screens'),
    // ASPECT RATIO:
    function ({ theme, addUtilities, variants }) {
      const base = {
        height: '0',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
      };

      addUtilities({
        '.ar-base': base,
      });

      Object.entries(theme('aspectRatio')).forEach(([key, value]) => {
        addUtilities(
          {
            [`.ar-${key}`]: {
              ...base,
              paddingBottom: `${100 / value}%`,
            },
          },
          {
            variants: variants('aspectRatio'),
          }
        );
      });
    },
  ],
};
